<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-datepicker
            type="year"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        직무스트레스
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-light-blue-box">
                    {{ props.row.planYear }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.jobStressPlanName }}</div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'jobStressPlan',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: null,
        userId: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: false,
            style: 'width: 80px',
          },
          {
            name: 'planYear',
            field: 'planYear',
            label: '년도',
            align: 'center',
            sortable: false,
            style: 'width: 80px',
          },
          {
            name: 'jobStressPlanName',
            field: 'jobStressPlanName',
            label: '평가계획명',
            align: 'left',
            sortable: false,
            style: 'width: 200px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '평가자',
            style: 'width:80px',
            align: "center",
            sortable: false,
            type: 'link'
          },
          {
            name: '0',
            field: '0',
            label: '물리적환경',
            child: [
              {
                name: 'physicalEnv',
                field: 'physicalEnv',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'physicalEnvScope',
                field: 'resultScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '1',
            field: '1',
            label: '직무요구',
            child: [
              {
                name: 'jobDemand',
                field: 'jobDemand',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'jobDemandScope',
                field: 'jobDemandScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '2',
            field: '2',
            label: '직무자율',
            child: [
              {
                name: 'jobAutonomy',
                field: 'jobAutonomy',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'jobAutonomyScope',
                field: 'jobAutonomyScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '3',
            field: '3',
            label: '관계갈등',
            child: [
              {
                name: 'relationship',
                field: 'relationship',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'relationshipScope',
                field: 'relationshipScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '4',
            field: '4',
            label: '직무불안정',
            child: [
              {
                name: 'jobInsecurity',
                field: 'jobInsecurity',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'jobInsecurityScope',
                field: 'jobInsecurityScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '5',
            field: '5',
            label: '조직체계',
            child: [
              {
                name: 'organizational',
                field: 'organizational',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'organizationalScope',
                field: 'organizationalScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '6',
            field: '6',
            label: '보상부적절',
            child: [
              {
                name: 'inadequate',
                field: 'inadequate',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'inadequateScope',
                field: 'inadequateScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
          {
            name: '7',
            field: '7',
            label: '직장문화',
            child: [
              {
                name: 'workplace',
                field: 'workplace',
                label: '점수',
                style: 'width:50px',
                align: "right",
                sortable: false,
                type: 'cost',
              },
              {
                name: 'workplaceScope',
                field: 'workplaceScope',
                label: '대상군',
                style: 'width:50px',
                align: "center",
                sortable: false,
                type: 'custom'
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.jobStress.user.list.url
      // code setting
      // 로그인자 본인것만 작성
      this.$set(this.searchParam, 'userId', this.$store.getters.user.userId)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = '개인별 직무스트레스 평가 상세';
      this.popupOptions.param = {
        heaJobStressAssessmentUserId: row ? row.heaJobStressAssessmentUserId : '',
      };
      this.popupOptions.target = () => import(`${'./jobStressAssessmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
